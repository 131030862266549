export class StringUtil {
  static snakeToCamel(str: string): string {
    return str.replace(/_([a-z])/g, (mch) => mch[1].toUpperCase());
  }

  static camelToSnake(str: string): string {
    return str.replace(/([A-Z])/g, (mch) => '_' + mch.charAt(0).toLowerCase());
  }

  static toSnakeJSON(obj: any): string {
    return JSON.stringify(StringUtil.camelObjectToSnakeObject(obj));
  }

  static camelObjectToSnakeObject(obj: any): any {
    return StringUtil.recursiveKeyChange(obj, StringUtil.camelToSnake);
  }

  static snakeObjectToCamelObject(obj: any): any {
    return StringUtil.recursiveKeyChange(obj, StringUtil.snakeToCamel);
  }

  private static recursiveKeyChange(
    obj: any,
    keyChange: (key: string) => string,
  ): any {
    if (obj instanceof Array) {
      return obj.map((content) =>
        StringUtil.recursiveKeyChange(content, keyChange),
      );
    } else if (obj instanceof Object) {
      return Object.keys(obj).reduce((result: any, key: string) => {
        if (obj[key] instanceof Date) {
          result[keyChange(key)] = obj[key].toISOString();
        } else {
          result[keyChange(key)] = StringUtil.recursiveKeyChange(
            obj[key],
            keyChange,
          );
        }

        return result;
      }, {});
    } else {
      return obj;
    }
  }
}
